@media (max-width: 575px) {
  #productImgCarousel .carousel-item img {
    height: 315px;
  }

  .product-details {
    h1 {
      font-size: 16px;
      font-weight: normal;
    }

    h3 {
      font-size: 22px;
    }
  }
}
